<template>
  <div class="shop">
    <div class="row">
      <div class="col-12 scroller">
        <ion-content>
          <ion-refresher slot="fixed" @ionRefresh="reloadContent($event)">
            <ion-refresher-content></ion-refresher-content>
          </ion-refresher>
          <div class="energieshop mx-3" @submit="loadStep" v-html="content"></div>
        </ion-content>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import connector from "@/assets/helpers/connector"
import toast from "@/assets/helpers/toast"
import scroller from "@/assets/helpers/scroller"
import {
  IonContent,
  IonRefresher,
  IonRefresherContent
} from '@ionic/vue'

export default defineComponent({
  components: {
    IonContent,
    IonRefresher,
    IonRefresherContent
  },
  data() {
    return {
      isLoaded: false,
      content: ''
    };
  },
  methods: {
    loadContent: async function () {
      if (this.isLoaded) {
        return;
      }
      this.isLoaded = true;
      /** API Call */
      this.content = await connector.apiCall('shop');
    },
    loadStep: async function (event) {
      if (event) {
        event.preventDefault()
        let formElement = event.target;
        let shopData = new FormData(formElement);
        let stepFrom = event.submitter.getAttribute('data-from');
        let stepTo = event.submitter.getAttribute('data-to');
        if(stepFrom){
          shopData.append('cs_shop_step_from', stepFrom);
        }
        if(stepTo){
          shopData.append('cs_shop_step', stepTo);
        }

        /*if(shopData){
          const values = [...shopData.entries()];
          console.log(values);
        }*/
        this.isLoaded = true;
        this.content = '';
        this.content = await connector.apiCall('shop', shopData);
      }else{
        toast.showToast('Es ist ein Fehler aufgetreten.', 'danger');
      }
    },
    reloadStep: async function (target, step) {
      if (target) {
        let formElement = target;
        let shopData = new FormData(formElement);
        let stepFrom = step;
        let stepTo = step;

        if(stepFrom){
          shopData.append('cs_shop_step_from', stepFrom);
        }
        if(stepTo){
          shopData.append('cs_shop_step', stepTo);
        }

        /*if(shopData){
          const values = [...shopData.entries()];
          console.log(values);
        }*/
        this.isLoaded = true;
        this.content = '';
        this.content = await connector.apiCall('shop', shopData);
      }else{
        toast.showToast('Es ist ein Fehler aufgetreten.', 'danger');
      }
    },
    /**
     *
     * @param event
     * @returns {Promise<AxiosResponse<any>>}
     */
    async reloadContent(event = null) {
      this.isLoaded = false;
      try {
        let reloadSuccess = false;
        //erstes Formular suchen
        let shopContainer = document.querySelector('.shop');
        if(shopContainer!=null) {
          let forms = shopContainer.querySelectorAll('form');
          if (forms != null) {
            let firstForm = forms[0];
            if (firstForm != null) {
              let stepFromElement = firstForm.querySelector('input[data-from]');
              let stepFrom = stepFromElement.dataset.from;
              reloadSuccess = true;
              //schritt neu laden
              await this.reloadStep(firstForm, stepFrom);
            }
          }
          //wenn reload des Schritts nicht möglich, Info ausgeben aber kein reload
          if(reloadSuccess == false){
            await toast.showToast('Es ist ein Fehler aufgetreten.', 'warning');
          }
        }
      } catch (error) {
        await toast.showToast('Der Shop konnte nicht geladen werden.', 'danger');
      }

      //refresher ausblenden
      if (event !== null) {
        setTimeout(() => {
          event.target.complete();
        }, 100);
      }
    }
  },
  async beforeMount() {
    await this.loadContent();
  },
  mounted() {
    scroller.setScrollerHeight('.scroller', ['header','.menu-fixed-bottom']);
  }
})
</script>