<template>
  <div class="agb">
    <div class="row mx-3 scroller">
      <div class="col-12">
        <h1>Allgemeine Geschäftsbedingungen der Firma Das Ölmännchen  GmbH</h1>
        <p><strong>§1.      Geltungsbereich</strong><br>
          Für alle Waren- und Dienstleistungsgeschäfte der Firma Das Ölmännchen GmbH sind die nachstehenden Bedingungen maßgebend. Sämtliche –auch zukünftige- Lieferungen und Leistungen einschließlich Vorschläge, Beratungen und sonstigen Nebenleistungen erfolgen ausschließlich aufgrund dieser Bedingungen, falls keine abweichenden Sonderbedingungen / Individualabreden vereinbart worden sind. Abweichenden, entgegenstehenden oder ergänzenden Einkaufs- und / oder Bestellbedingungen des Vertragspartners (Kunde) wird hiermit widersprochen. Das gilt in jedem Fall, also auch dann, wenn in Kenntnis der AGB des Kunden die Leistung an ihn vorbehaltlos ausgeführt wird.</p>
        <p><strong>§2./§3.      Vertragsabschluss / Preise und Preiserhöhung</strong><br>
          1. Sämtliche Anpreisungen oder Werbung für Waren sowie Angebote der Verkäuferin, auch im Internet, erfolgen freibleibend und stellen eine unverbindliche Aufforderung an den Käufer dar, Lieferungen und Leistungen zu bestellen. Durch die Bestellung gibt der Käufer ein verbindliches Angebot auf Abschluss eines Vertrages ab.<br>
          2. Die Verkäuferin ist berechtigt, das Angebot innerhalb von 14 Kalendertagen anzunehmen. Die Frist beginnt mit dem Tag des Zugangs der Bestellung durch den Kunden. Die Annahmeerklärung erfolgt durch die Auftragsbestätigung in Textform. Als Auftragsbestätigung gilt auch die tatsächliche Lieferung oder die Rechnungsstellung.<br>
          3. Die Beschaffenheit der gelieferten Ware entspricht den allgemeinen handelsüblichen DIN-Normen. Alle Muster, Proben, Mitteilungen von Analysedaten geben unverbindliche Anhaltspunkte für die durchschnittliche Beschaffenheit der Ware. Unwesentliche Abweichungen, insbesondere handelsübliche Abweichungen von der bestellten Ware, sind zulässig und gelten als vertragsgemäße Erfüllung.<br>
          4. Die Lieferung und Abrechnung erfolgt temperaturkompensiert auf der Basis von 15° C gemäß der 2. Verordnung zur Änderung der Eichordnung vom 21. Juni 1994.<br>
          5. Von der Verkäuferin veröffentlichte Preise für Heizölprodukte sind freibleibende Angebote. Soweit nicht anders angegeben, verstehen sich alle Preise netto zzgl. der gesetzlichen Mehrwertsteuer. Alle weiteren Steuern und Abgaben sind inkludiert. Die bei der Bestellung ausgewählten Lieferbedingungen (siehe §4) sind maßgeblich für den Angebotspreis.<br>
          6. Erfolgt die Lieferung später als 4 Monate nach Vertragsschluss, werden zwischenzeitlich eingetretene Materialpreisänderungen, Transportkostenänderungen, Tarifänderungen, Eis-, Hoch- oder Niedrigwasserzuschläge oder Steueränderungen bei der Kaufpreisbemessung berücksichtigt.<br>
          7. Beim Kauf steuerbegünstigter Ware haftet der Kunde dafür, dass die Firma Das Ölmännchen  GmbH zum Zeitpunkt der Lieferung über einen gültigen Erlaubnisschein verfügt, der auch die aktuelle Firmierung des Berechtigten ausweist. Wird die von der Firma Das Ölmännchen GmbH gelieferte steuerbegünstigte Ware vom Kunden unter Verletzung gesetzlicher Bestimmungen weitergegeben, und/ oder bestimmungswidrig verwendet, so ist er der Firma Das Ölmännchen GmbH zum Ersatz der Steuern verpflichtet, für die die Firma Das Ölmännchen GmbH als Steuer- oder Haftungsschuldner in Anspruch genommen wird.<br>
          8. Von der Firma Das Ölmännchen GmbH erstellte Abrechnungen sind vom Unternehmer unverzüglich auf ihre Richtigkeit im Hinblick auf den ausgewiesenen Umsatzsteuersatz zu überprüfen. Der Ausweis eines unrichtigen Umsatzsteuersatzes ist der Firma Das Ölmännchen GmbH binnen eines Monats ab Zugang der Abrechnung in Textform mitzuteilen. Sollte die Firma Das Ölmännchen GmbH des vorgenannten Zeitraumes keine Mitteilung des Unternehmers erhalten, ist der von der Firma Das Ölmännchen GmbH ausgewiesene Umsatzsteuersatz maßgeblich. Bei Verletzung der Mitteilungspflicht ist der Unternehmer der Firma Das Ölmännchen  GmbH nach den gesetzlichen Vorschriften zum Schadensersatz verpflichtet.<br>
          9. Sollte auf Kundenwunsch ein Auftrag storniert werden, ist eine Stornogebühr in Höhe von 15 % des Auftragswertes, mindestens jedoch 95,- €, zzgl. geltender Mehrwertsteuer, fällig.</p>
        <p><strong>§ 4 Lieferbedingungen; Lieferzeit</strong><br>
          1. Der Verkäufer verpflichtet sich, ausschließlich Qualitätsheizöl nach DIN 51603-1 (Heizöl EL) und ggf. handelsübliche Markenadditive zu liefern.<br>
          2. Lieferfristen und Liefertermine sind unverbindlich. Die Lieferfrist beginnt mit Zustandekommen des Vertrages, jedoch nicht vor der Beibringung etwaiger vom Kunden zu beschaffenden Unterlagen, Genehmigungen, Freigaben sowie nicht vor unwiderruflichem bzw. unanfechtbarem Eingang einer vereinbarten Anzahlung und nicht vor dem Zustandekommen der Finanzierung.<br>
          Sofern der Kunde keine abweichende Lieferfrist gewählt hat, gilt die Standard-Lieferfrist, ca. 30 Werktage<br>
        Werktage sind Montag bis Freitag. Wochenenden und Feiertage bleiben bei der Lieferfristberechnung unberücksichtigt. Für alle Lieferfristen ist die Auftragsbestätigung der Verkäuferin maßgeblich.<br>
        Bei Überschreitung der unverbindlichen Lieferfrist, hat der Käufer eine 14tägige Nachlieferfrist -beginnend vom Tage des Eingangs der Inverzugsetzung durch den Kunden oder im Fall kalendermäßig bestimmter Lieferfrist mit deren Ablauf – zu gewähren.<br>
          3. Bei Anlieferung von Heizöl oder Treibstoffen ist der Kunde für einen einwandfreien technischen Zustand des Tanks und der Messvorrichtung verantwortlich. Schäden, die durch Überlaufen entstehen, weil der Tank oder die Messvorrichtung sich in mangelhaftem technischen Zustand befinden, sowie Schäden, die durch Verschmutzung und/ oder Vermischung im eigenen Tank oder Tankwagen des Abnehmers enthaltenen Restbestand bzw. durch einen verschmutzten und/ oder Wasser enthaltenen Tank oder Tankwagen des Abnehmers entstehen, gehen zu Lasten des Kunden.<br>
        Die Lieferstelle muss mit einem Tankwagen der bestellten Größe erreichbar sein. Voraussetzung für die Anlieferung ist eine mit schwerem Lastzug befahrbare Anfuhrstraße. Verlässt das Lieferfahrzeug auf Weisung des Kunden die befahrbare Anfuhrstraße, so haftet dieser für auftretende Schäden.<br>
          Eventuelle Zufahrts- oder Lieferbeschränkungen (Wasserschutzgebiet, Gewichtsbeschränkung, etc.) müssen bei der Bestellung angegeben werden und werden als Hinweis für den Fahrer in der schriftlichen Auftragsbestätigung angegeben.<br>
          4. Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der Ware geht spätestens mit der Übergabe auf den Kunden über. Beim Versendungsverkauf geht jedoch die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung sowie die Verzögerungsgefahr bereits mit Auslieferung der Ware an den Spediteur, den Frachtführer oder der sonst mit der Ausführung der Versendung bestimmten Personen oder Anstalt über. Soweit eine Abnahme vereinbart ist, ist diese für den Gefahrübergang maßgebend. Auch im Übrigen gelten für eine vereinbarte Abnahme die gesetzlichen Vorschriften des Werkvertragsrechts entsprechend. Der Übergabe bzw. Abnahme steht es gleich, wenn der Kunde im Verzug der Annahme ist.<br>Die Firma Das Ölmännchen GmbH ist berechtigt, die vertragliche Leistung in Teillieferungen zu erbringen, wenn dies für den Kunden zumutbar ist. Ist Lieferung auf Abruf vereinbart, so hat der Kunde innerhalb angemessener Frist abzurufen.<br>
          5. Wird die Lieferung durch höhere Gewalt, also ein betriebsfremdes, von außen durch elementare Naturkräfte oder durch Handlungen dritter Personen herbeigeführtes Ereignis, das nach menschlicher Einsicht und Erfahrung unvorhersehbar ist, mit wirtschaftlich erträglichen Mitteln auch durch die äußerste, nach der Sachlage vernünftigerweise zu erwartende Sorgfalt nicht verhütet oder unschädlich gemacht werden kann und auch nicht wegen seiner Häufigkeit vom Betriebsunternehmer in Kauf zu nehmen ist, behördliche Maßnahmen, Betriebsstillegung, Streik, extreme Witterungsverhältnisse, Transportstörungen, Epidemie, Pandemie, Seuchen, Krieg, Unruhen oder ähnliche Umstände - auch bei Lieferanten der Firma Das Ölmännchen GmbH - unmöglich oder übermäßig erschwert, so wird die Firma Das Ölmännchen GmbH für die Dauer der Behinderung und deren Nachwirkung von der Lieferpflicht frei. Von dem Eintritt solcher Ereignisse wird die Firma Das Ölmännchen GmbH den Kunden unverzüglich unterrichten. Diese Ereignisse berechtigen die Firma Das Ölmännchen GmbH auch, vom Vertrag zurückzutreten. Im Falle der Nichtbelieferung oder ungenügender Belieferung der Firma Das Ölmännchen  GmbH seitens ihrer Vorlieferanten ist die Firma Das Ölmännchen GmbH von ihren Lieferverpflichtungen ganz oder teilweise entbunden. Dies gilt nur dann, wenn sie die erforderlichen Vorkehrungen zur Beschaffung der von ihr zu liefernden Ware getroffen hat und ihre Vorlieferanten sorgfältig ausgewählt hat. Sie verpflichtet sich, in diesem Fall ihre Ansprüche gegen den Lieferanten auf Verlangen an den Kunden abzutreten.</p>
        <p><strong>§5.      Sachmängel, Verjährung</strong><br>
          1. Für Unternehmer gelten die nachfolgenden Regelungen:<br>
          aa) Die Ware muss sofort nach Eingang auf Sachmängel z.B. Menge, Qualität, Beschaffenheit geprüft werden. Der Kunde ist verpflichtet, offensichtliche Mängel auf der Empfangsquittung zu vermerken. Im Übrigen gilt § 377 HGB.<br>
          bb) Rügen wegen offensichtlich mangelhafter oder offensichtlich abweichender Beschaffenheit der Ware oder wegen Lieferung einer offensichtlich anderen Ware als der bestellten können nur unverzüglich, spätestens jedoch innerhalb einer Woche nach Empfang der Ware bzw. nachdem der Mangel offensichtlich wurde, gegenüber der Das Ölmännchen GmbH geltend gemacht werden.<br>
          cc) Beschädigungen auf dem Transport berechtigen der Firma Das Ölmännchen GmbH gegenüber nicht zur Annahmeverweigerung.<br>
          dd) Die Haftung der Firma Das Ölmännchen GmbH für Körper- und Gesundheitsschäden sowie die Haftung für vorsätzlich oder grob fahrlässig verursachte Schäden, für Arglist oder bei Übernahme einer Garantie bleibt unberührt.<br>
          ee) Abweichend von § 438 Abs. 1 Nr. 3 BGB beträgt die allgemeine Verjährungsfrist für Ansprüche aus Sach- und Rechtsmängeln ein Jahr ab Ablieferung. Soweit eine Abnahme vereinbart ist, beginnt die Verjährung mit der Abnahme.<br>
          ff) zwingende Verjährungsvorschriften bleiben unberührt. Die in Absatz ee) genannte Verjährungserleichterung gilt nicht für Ansprüche wegen der Verletzung von Leben, Körper oder Gesundheit, für Ansprüche aufgrund von Vorsatz und/ oder grober Fahrlässigkeit und für Ansprüche aufgrund der Übernahme einer Garantie oder der Übernahme des Beschaffungsrisikos. Unberührt bleiben auch die längeren Verjährungsfristen nach § 438 Abs. 1 Nr. 1 BGB (dingliche Rechte eines Dritten), §§ 438 Abs. 1 Nr. 2, 634a Abs. 1 Nr. 2 BGB (Bauwerke, Baustoffe und Bauteile sowie Planungsleistungen für ein Bauwerk), §§ 438 Abs. 3 und 634a Abs. 3 BGB (Arglist). Ist der letzte Vertrag in der Lieferkette ein Verbrauchsgüterkauf i.S.d. § 474 BGB (d.h. bei Endlieferung der Ware an einen Verbraucher), bleiben auch die Verjährungsfristen gemäß § 445b BGB unberührt.<br>
          gg) Die sich nach den Absätzen ee) und ff) für Ansprüche wegen Sach- und Rechtsmängel ergebenden Verjährungsfristen gelten entsprechend für konkurrierende vertragliche und außervertragliche Schadensersatzansprüche des Kunden, die auf eine Mangel der Vertragsware beruhen. Wenn jedoch im Einzelfall die Anwendung der gesetzlichen Verjährungsregeln zu einer früheren Verjährung der konkurrierenden Ansprüche führen sollte, gilt für die konkurrierenden Ansprüche die gesetzliche Verjährungsfrist. Die gesetzlichen Verjährungsfristen nach dem Produkthaftungsgesetz bleiben in jedem Fall unberührt.<br>
          hh) Soweit gemäß Absatz ee) bis gg) die Verjährung von Ansprüchen der Firma Das Ölmännchen GmbH gegenüber verkürzt wird, gilt diese Verkürzung entsprechend für etwaige Ansprüche des Kunden gegen die gesetzlichen Vertreter, Angestellten, Mitarbeiter, Beauftragten sowie Verrichtungs- und Erfüllungsgehilfen der Firma Das Ölmännchen GmbH, die auf demselben Rechtsgrund beruhen.<br>
          2. Für Verbraucher gelten die folgenden Regelungen:<br>
          Mängelansprüche und Schadensersatzansprüche, die in unmittelbaren Zusammenhang mit einem Mangel stehen, verjähren für gebrauchte bewegliche Sachen innerhalb eines Jahres nach Übergabe der gebrauchten beweglichen Sache. Die Haftung der Firma Das Ölmännchen GmbH für Körper- und Gesundheitsschäden sowie die Haftung für vorsätzlich oder grob fahrlässig verursachte Schäden, für Arglist oder bei Übernahme einer Garantie bleibt in jedem Fall unberührt.</p>
        <p><strong>§6.      Liefer- und Zahlungsoptionen</strong><br>
          1.Für alle Bestellungen ohne Sonderoptionen gelten die folgenden Grundsätze:</p>
          <ul>
            <li>Die zur Betankung erforderliche Schlauchlänge darf 40m nicht überschreiten</li>
            <li>Es gilt die angezeigte Standard Lieferfrist</li>
            <li>Die Anlieferung erfolgt u.U. mit einem Tanklastzug (Gliederzug) mit einem zulässigen Gesamtgewicht von 40 t und 2,60m Breite</li>
          </ul>
        <p>2. Soweit nicht anders vereinbart, erfolgt die Bezahlung in bar bei Lieferung. EC-Karten-Zahlung ist möglich, wenn sie bei der Bestellung gebucht wurde oder der Verkäufer zustimmt. Sonderoptionen, wie z.B. ein spezieller Zahlungswunsch, schnellere Lieferung, längerer Schlauch, kleinerer Tankwagen oder Terminlieferung kann der Käufer während des Bestellvorgangs hinzu buchen. Ist die gewählte Option mit einem Aufpreis verbunden, erhöht sich der Preis entsprechend.<br>
          Die Rechnungserstellung erfolgt 7 bis 14 Werktage nach der Lieferung. Nach Ablauf einer Frist von 30 Tagen ab Zugang der Rechnung oder mit Erhalt einer Mahnung tritt Zahlungsverzug ein.<br>
          Bei Verzug fallen Verzugszinsen nach den gesetzlichen Bestimmungen an.<br>
          3. Die Firma Das Ölmännchen GmbH kann die sofortige Bezahlung aller Forderungen verlangen und Lieferungen von Vorauszahlung oder Leistung einer Sicherheit abhängig machen, wenn eine wesentliche Verschlechterung der Vermögens- oder Einkommensverhältnisse des Kunden oder bei ihm eine erhebliche Vermögensgefährdung eintritt.<br>
          4. Die Firma Das Ölmännchen GmbH kann mit sämtlichen Forderungen, die ihr gegen den Kunden zustehen, gegen sämtliche Forderungen aufrechnen, die der Kunde gegen sie hat. Der Kunde kann nur mit unbestrittenen oder rechtskräftig festgestellten fälligen Gegenansprüchen aufrechnen. Der Kunde der Firma Das Ölmännchen GmbH kann ein Zurückbehaltungsrecht, das nicht auf demselben rechtlichen Verhältnis beruht, nicht ausüben.</p>
        <p><strong>§7       Leistungsstörungen</strong><br>
          1. Der Kaufpreis wird sofort fällig, wenn der Kunde die Zahlung des Kaufpreises endgültig verweigert oder vereinbarte Ratenzahlungen nicht einhält. Die Firma Das Ölmännchen GmbH kann in diesen Fällen auch ohne Setzung einer Nachfrist die Erfüllung des Kaufvertrages ablehnen und Ersatz aller entstandenen Kosten, Auslagen sowie Entschädigung für Wertminderung verlangen.<br>
          2. Bei Annahmeverzug des Kunden kann die Firma Das Ölmännchen GmbH die Ware auf Kosten und Gefahr des Kunden bei sich oder einem Dritten lagern oder in geeigneter Weise auf Rechnung des Kunden verwerten, ohne dass es hierzu einer Ankündigung bedarf.</p>
        <p><strong>§8       Eigentumsvorbehalt/ Rücknahmerecht</strong><br>
          1. Die Firma Das Ölmännchen GmbH  behält sich das Eigentum an den gelieferten Waren bis zum Eingang aller Zahlungen aus der Geschäftsverbindung mit dem Kunden vor.<br>
          Bei vertragswidrigem Verhalten des Kunden, insbesondere bei Zahlungsverzug ist die Firma Das Ölmännchen GmbH nach Setzung einer angemessenen Frist, zum Rücktritt, und dementsprechend zur Rücknehme, berechtigt. Der Kunde gestattet der Firma Das Ölmännchen GmbH  bereits jetzt zum Zweck der Durchführung der Rücknahme den ungehinderten Zutritt zu seinem bzw. dem von ihm gemieteten, gepachteten oder sonst genutzten Grundstücks sowie dem Tankraum und der hierhin führenden Gebäudeflächen.<br>
          Die Firma Das Ölmännchen GmbH ist nach Rücknahme der Kaufsache zu deren Verwertung befugt, der Verwertungserlös ist auf die Verbindlichkeiten des Kunden abzüglich angemessener Verwertungskosten anzurechnen.<br>
          2. Die Vorbehaltsware darf vom Kunden ohne ausdrückliche schriftliche Zustimmung der Firma Das Ölmännchen GmbH  vor vollständiger Bezahlung der gesicherten Forderungen weder an Dritte verpfändet noch zur Sicherheit übereignet werden. Bei Pfändungen oder sonstigen Eingriffen Dritter hat der Kunde die Firma Das Ölmännchen GmbH unverzüglich schriftlich zu benachrichtigen. Etwaige der Firma Das Ölmännchen GmbH entstehende Gerichtskosten etc. sind vom Kunden zu erstatten.<br>
          3. Der Kunde ist berechtigt, die gelieferten Waren im ordentlichen Geschäftsgang weiter zu verkaufen; er tritt der Firma Das Ölmännchen  GmbH jedoch bereits jetzt alle Forderungen in Höhe des Faktura-Endbetrages (einschließlich MwSt.) ihrer Forderungen ab, die ihm aus der Weiterveräußerung gegen seine Abnehmer oder Dritte erwachsen, und zwar unabhängig davon, ob die Kaufsache ohne oder nach Verarbeitung weiterverkauft worden ist. Zur Einziehung dieser Forderung bleibt der Kunde auch nach der Abtretung ermächtigt. Die Befugnis der Firma Das Ölmännchen GmbH, die Forderung selbst einzuziehen, bleibt hiervon unberührt. Die Firma Das Ölmännchen GmbH verpflichtet sich jedoch, die Forderung nicht einzuziehen, solange keine Wechsel- und Scheckproteste vorkommen, der Kunde seinen Zahlungsverpflichtungen aus den vereinnahmten Erlösen nachkommt, nicht in Zahlungsverzug ist und kein Antrag auf Eröffnung des Insolvenzverfahrens über sein Vermögen gestellt ist. Ist dies aber der Fall, kann die Firma Das Ölmännchen GmbH verlangen, dass der Kunde ihr die abgetretenen Forderungen und deren Schuldner bekannt gibt, alle zum Einzug erforderlichen Angaben macht, die dazugehörigen Unterlagen aushändigt und den Schuldnern (Dritten) die Abtretung mitteilt<br>
          4. Die Verarbeitung oder Umbildung der von der Firma Das Ölmännchen  GmbH gelieferten Vorbehaltsware durch den Kunden wird stets für die Firma Das Ölmännchen GmbH vorgenommen. Wird die von der Firma Das Ölmännchen  GmbH gelieferte Vorbehaltsware mit anderen, ihr nicht gehörenden Gegenständen/ Stoffen verarbeitet, so erwirbt sie das Miteigentum an der neuen Sache im Verhältnis des Wertes der Vorbehaltsware zu den anderen verarbeiteten Gegenständen/ Stoffen zur Zeit der Verarbeitung. Für die durch Verarbeitung entstehende Sache gilt im Übrigen das gleiche wie für die unter Vorbehalt gelieferte Ware.<br>
          5. Wird die von der Firma Das Ölmännchen GmbH gelieferte Vorbehaltsware mit anderen, der Firma Das Ölmännchen GmbH nicht gehörenden Gegenständen/ Stoffen untrennbar vermischt oder dergestalt verbunden, dass sie wesentliche Bestandteile einer einheitlichen Sache werden, so erwirbt die Firma Das Ölmännchen GmbH das Miteigentum an der neuen Sache im Verhältnis des Wertes der Vorbehaltsware zu den anderen vermischten oder verbundenen Gegenständen/ Stoffen zum Zeitpunkt der Verbindung oder Vermischung. Erfolgt die Verbindung oder Vermischung in der Weise, dass die Sache des Kunden als Hauptsache anzusehen ist, so gilt bereits jetzt als vereinbart, dass der Kunde der Firma Das Ölmännchen  GmbH anteilmäßig Miteigentum überträgt. Der Kunde verwahrt das so entstandene Miteigentum für die Firma Das Ölmännchen  GmbH. Für die durch Verbindung oder Vermischung entstandene Sache gilt im Übrigen das Gleiche wie für die unter Vorbehalt gelieferte Ware.<br>
          6. Der Kunde ist verpflichtet, die Vorbehaltsware pfleglich zu behandeln, insbesondere ist er verpflichtet, diese auf eigene Kosten gegen Feuer, Wasserschäden und Diebstahl ausreichend zum Neuwert zu versichern. Sofern Wartungs- und Inspektionsarbeiten erforderlich sind, muss der Kunde diese auf eigene Kosten rechtzeitig durchführen.<br>
          7. Für den Fall des Untergangs oder der Beschädigung der Vorbehaltsware tritt der Kunde in diesem Zusammenhang bestehende etwaige Ansprüche auf Versicherungsleistungen in Höhe des Faktura-Endbetrages (einschließlich Mehrwertsteuer) der Forderungen der Firma Das Ölmännchen GmbH in Ansehung des Liefergegenstandes als zusätzliche Sicherheit im Voraus an die Firma Das Ölmännchen GmbH ab.<br>
          8. Die Firma Das Ölmännchen GmbH verpflichtet sich, die ihr zustehenden Sicherheiten auf Verlangen des Kunden insoweit freizugeben, als der realisierbare Wert ihrer Sicherheiten die zu sichernden Forderungen um mehr als 20 % übersteigt; die Auswahl der freizugebenden Sicherheiten obliegt der Firma Das Ölmännchen GmbH.</p>
        <p><strong>§9        Haftungsausschlüsse und -begrenzungen</strong><br>
          1. Vorbehaltlich der Regelung des nachfolgenden Absatzes haftet die Firma Das Ölmännchen GmbH auf Schadensersatz – bei vertraglichen, außervertraglichen oder sonstigen Schadensersatzansprüchen, gleich aus welchem Rechtsgrund, insbesondere wegen Mängeln, Verzug und Unmöglichkeit, Verschulden bei Vertragsverhandlungen und Delikt – nur bei Vorsatz und/ oder grober Fahrlässigkeit, einschließlich Vorsatz und/ oder grober Fahrlässigkeit der Vertreter der Firma Das Ölmännchen  GmbH oder Erfüllungsgehilfen. Darüber hinaus haftet die Firma Das Ölmännchen  GmbH auch bei einfacher Fahrlässigkeit, einschließlich einfacher Fahrlässigkeit der Vertreter der Firma Das Ölmännchen  GmbH und Erfüllungsgehilfen, für Schäden aus der Verletzung einer wesentlichen Vertragspflicht, d.h. einer Pflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages erst ermöglicht und auf deren Erfüllung der Kunde daher regelmäßig vertrauen darf (Kardinalpflicht). Soweit der Firma Das Ölmännchen GmbH keine vorsätzliche Pflichtverletzung angelastet wird, ist die Schadensersatzhaftung jedoch auf den vorhersehbaren, typischerweise eintretenden Schaden begrenzt.<br>
          2. Von den Absatz 1. geregelten Haftungsausschlüssen und –beschränkungen unberührt bleiben Ansprüche für Schäden aus der Verletzung des Lebens, des Körpers und der Gesundheit sowie Ansprüche des Kunden nach dem Produkthaftungsgesetz, den gesetzlichen Sondervorschriften bei Endlieferung der Ware an einen Verbraucher und anderen zwingenden gesetzlichen Haftungsregelungen. Die vorstehenden Haftungsausschlüsse bzw. –Beschränkungen gelten außerdem nicht, soweit die Firma Das Ölmännchen GmbH einen Mangel arglistig verschwiegen hat oder soweit die Firma Das Ölmännchen GmbH aus der Übernahme einer Garantie oder wegen der Übernahme des Beschaffungsrisikos haftet.<br>
          3. Die voranstehenden Absätze 1. und 2. gelten auch, wenn der Kunde anstelle eines Anspruchs auf Ersatz des Schadens statt der Leistung Ersatz nutzloser Aufwendungen verlangt.<br>
          4. Soweit die Schadensersatzhaftung der Firma Das Ölmännchen  GmbH gegenüber ausgeschlossen oder eingeschränkt ist, gilt dies auch im Hinblick auf die persönliche Schadensersatzhaftung der Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter der Firma Das Ölmännchen GmbH und Erfüllungsgehilfen, die auf demselben Rechtsgrund beruhen.</p>
        <p><strong>§10     Datenschutz</strong><br>
          Firma Das Ölmännchen GmbH erhebt, speichert und verarbeitet personenbezogene Daten in Übereinstimmung mit den Bestimmungen der Datenschutzgrundverordnung (DSGVO). Die Firma Das Ölmännchen GmbH wird personenbezogene Daten an Auskunfteien (z.B. Schufa, Creditreform) über nicht vertragsgemäßes Verhalten unter Beachtung der Vorschriften der DSGVO übermitteln. Übermittelt der Kunde als verantwortliche Stelle gem. Art. 4 Nr. 7 DSGVO RWZ personenbezogene Daten, so ist er verpflichtet, den Betroffenen rechtzeitig nach Maßgabe des Artikel 14 DSGVO über die Datenverarbeitung durch Firma Das Ölmännchen  GmbH zu informieren; Firma Das Ölmännchen  GmbH sieht von einer Information des Betroffenen ab.</p>
        <p><strong>§11     Erfüllungsort, geltendes Recht, Gerichtsstand</strong><br>
          Die Geschäftsräume der Firma Das Ölmännchen GmbH sind für beide Teile Erfüllungsort, wenn der Kunde Kaufmann, eine juristische Person öffentlichen Rechts oder ein öffentlich- rechtliches Sondervermögen ist.<br>
          Die Rechtsbeziehungen der Parteien unterliegen Deutschem Recht. Die Anwendung des Übereinkommens der Vereinten Nationen über internationale Warenverkäufe (CISG) ist ausgeschlossen.<br>
          Gerichtsstand ist, soweit gesetzlich zulässig, Köln</p>
        <p><strong>Widerrufsbelehrung Das Ölmännchen GmbH</strong></p>
        <p>Beim Heizölkauf besteht das gesetzliche Widerrufsrecht für Verbraucherkunden nicht, weil auf Verträge über die Lieferung von Heizöl der Ausschlussgrund des § 312g Abs. 2 Nr. 8 BGB anwendbar ist. Verbraucher können ihre auf Abschluss des Vertrages gerichtete Willenserklärung also nicht widerrufen.</p>
        <p>Der Preis der Ware hängt von Schwankungen auf dem Finanzmarkt ab, auf die der Unternehmer (Heizölhändler) keinen Einfluss hat und die innerhalb der Widerrufsfrist auftreten können. Für gewerblich handelnde Käufer besteht generell kein Widerrufsrecht.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import scroller from "@/assets/helpers/scroller"

export default defineComponent({
  mounted() {
    scroller.setScrollerHeight('.scroller', ['header','.menu-fixed-bottom']);
  }
})
</script>