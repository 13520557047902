import Storage from "@/assets/helpers/storage"

export default {
    isInitialised() {
        let appData = Storage.readAppData();
        if(typeof appData['init_complete'] != 'undefined'){
            if(appData['init_complete'] == 'true'){
                return true
            }
        }
        return false
    }
}