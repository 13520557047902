<template>
  <div class="datenschutz">
    <div class="row mx-3 scroller">
      <div class="col-12">
        <h1>Informationen zum Datenschutz nach EU-DSGVO</h1>
        <p>Unser Unternehmen prüft regelmäßig bei Vertragsabschlüssen und in bestimmten Fällen, in denen ein berechtigtes Interesse vorliegt auch bei Bestandskunden Ihre Bonität. Dazu arbeiten wir mit der Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss zusammen, von der wir die dazu benötigten Daten erhalten. Im Auftrage von Creditreform Boniversum teilen wir Ihnen bereits vorab dazu folgende Informationen gem. Art. 14 EU-DSGVO mit: Die Creditreform Boniversum GmbH ist eine Konsumentenauskunftei. Sie betreibt eine Datenbank, in der Bonitätsinformationen über Privatpersonen gespeichert werden. Auf dieser Basis erteilt Creditreform Boniversum Bonitätsauskünfte an ihre Kunden. Zu den Kunden gehören beispielsweise Kreditinstitute, Leasinggesellschaften, Versicherungen, Telekommunikationsunternehmen, Unternehmen des Forderungsmanagements, Versand-, Groß[1]und Einzelhandelsfirmen sowie andere Unternehmen, die Waren oder Dienstleistungen liefern bzw. erbringen. Im Rahmen der gesetzlichen Bestimmungen wird ein Teil der in der Auskunftsdatenbank vorhandenen Daten auch für die Belieferung anderer Firmendatenbanken, u. a. zur Verwendung für Adress-Handelszwecke genutzt. In der Datenbank der Creditreform Boniversum werden insbesondere Angaben gespeichert über den Namen, die Anschrift, das Geburtsdatum, ggf. die E-Mailadresse, das Zahlungsverhalten und die Beteiligungsverhältnisse von Personen. Zweck der Verarbeitung der gespeicherten Daten ist die Erteilung von Auskünften über die Kreditwürdigkeit der angefragten Person. Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1f EU-DSGVO. Auskünfte über diese Daten dürfen danach nur erteilt werden, wenn ein Kunde ein berechtigtes Interesse an der Kenntnis dieser Informationen glaubhaft darlegt. Sofern Daten in Staaten außerhalb der EU übermittelt werden, erfolgt dies auf Basis der sog. „Standardvertragsklauseln“, die Sie unter folgendem Link:</p>
        <p><a href="http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32001D0497&from=DE" class="link-extern">http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32001D0497&from=DE</a></p>
        <p>einsehen oder sich von dort zusenden lassen können.</p>
        <p>Die Daten werden so lange gespeichert, wie ihre Kenntnis für die Erfüllung des Zwecks der Speicherung notwendig ist. Notwendig ist die Kenntnis in der Regel für eine Speicherdauer von zunächst vier Jahren. Nach Ablauf wird geprüft, ob eine Speicherung weiterhin notwendig ist, andernfalls werden die Daten Tag genau gelöscht. Im Falle der Erledigung eines Sachverhalts werden die Daten drei Jahre nach Erledigung Tag genau gelöscht. Eintragungen im Schuldnerverzeichnis werden gemäß § 882e ZPO nach Ablauf von drei Jahren seit dem Tag der Eintragungsanordnung Tag genau gelöscht. Weitere Einzelheiten können Sie den vom Verband „Die Wirtschaftsauskunfteien e.V“ aufgestellten „Verhaltensregeln für die Prüf- und Löschfristen von Personenbezogenen Daten durch die deutschen Wirtschaftsauskunfteien“ entnehmen.</p>
        <p>Berechtigte Interessen im Sinne des Art. 6 Abs. 1f EU-DSGVO können sein: Kreditentscheidung, Geschäftsanbahnung, Beteiligungsverhältnisse, Forderung, Bonitätsprüfung, Versicherungsvertrag, Vollstreckungsauskunft. Sie haben gegenüber der Creditreform Boniversum GmbH ein Recht auf Auskunft über die dort zu Ihrer Person gespeicherten Daten. Soweit die über Sie gespeicherten Daten falsch sein sollten, haben Sie einen Anspruch auf Berichtigung oder Löschung. Kann nicht sofort festgestellt werden, ob die Daten falsch oder richtig sind, haben Sie bis zur Klärung einen Anspruch auf Sperrung der jeweiligen Daten. Sind Ihre Daten unvollständig, so können Sie deren Vervollständigung verlangen.</p>
        <p>Sofern Sie Ihre Einwilligung zur Verarbeitung der bei Creditreform Boniversum gespeicherten Daten gegeben haben, haben Sie das Recht, diese Einwilligung jederzeit zu widerrufen. Durch den Widerruf wird die Rechtmäßigkeit der aufgrund Ihrer Einwilligung bis zu einem etwaigen Widerruf erfolgten Verarbeitung Ihrer Daten nicht berührt. Sollten Sie Einwände, Wünsche oder Beschwerden zum Datenschutz haben, können Sie sich jederzeit an den Datenschutzbeauftragten der Creditreform Boniversum wenden. Dieser wird Ihnen schnell und vertrauensvoll in allen Fragen des Datenschutzes weiterhelfen. Sie können sich auch über die Verarbeitung der Daten durch Boniversum bei dem für Ihr Bundesland zuständigen Landesbeauftragten für Datenschutz beschweren. Die Daten, die Creditreform Boniversum zu Ihnen gespeichert hat, stammen aus öffentlich zugänglichen Quellen, von Inkassounternehmen und von deren Kunden. Um Ihre Bonität zu beschreiben bildet Creditreform Boniversum zu Ihren Daten einen Scorewert. In den Scorewert fließen Daten zu Alter und Geschlecht, Adressdaten und teilweise Zahlungserfahrungsdaten ein. Diese Daten fließen mit unterschiedlicher Gewichtung in die Scorewertberechnung ein. Die Creditreform Boniversum -Kunden nutzen die Scorewerte als Hilfsmittel bei der Durchführung eigener Kreditentscheidungen.</p>
        <p><strong>Widerspruchsrecht:</strong></p>
        <p>Die Verarbeitung der bei Creditreform Boniversum gespeicherten Daten erfolgt aus zwingenden schutzwürdigen Gründen des Gläubiger- und Kreditschutzes, die Ihre Interessen, Rechte und Freiheiten regelmäßig überwiegen oder dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Nur bei Gründen, die sich aus einer bei Ihnen vorliegenden besonderen Situation ergeben und nachgewiesen werden müssen, können Sie gegenüber der Creditreform Boniversum der Verarbeitung Ihrer Daten widersprechen. Liegen solche besonderen Gründe nachweislich vor, werden die Daten dort nicht mehr verarbeitet. Verantwortlich im Sinne des Art. 4 Nr. 7 EU-DSGVO ist die Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss. Ihr Ansprechpartner in unserem Haus ist der Consumer Ser[1]vice, Tel.: 02131 36845560, Fax: 02131 36845570, E-Mail: selbstauskunft@boniversum.de. Unseren Datenschutzbeauftragten erreichen Sie unter folgenden Kontaktdaten: Creditreform Boniversum GmbH, Datenschutzbeauftragter, Hammfelddamm 13, 41460 Neuss,</p>
        <p>E-Mail: datenschutz@boniversum.de</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import scroller from "@/assets/helpers/scroller"

export default defineComponent({
  mounted() {
    scroller.setScrollerHeight('.scroller', ['header','.menu-fixed-bottom']);
  }
})
</script>