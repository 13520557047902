export default {
    showToast(message='', type='success') {
        let toastElement = document.getElementById('toast')
        if(toastElement) {

            toastElement.children[1].innerHTML = message

            let toastClass = document.createAttribute('class');
            toastClass.value = 'bg-'+type;
            toastElement.setAttributeNode(toastClass);

            let toast = new bootstrap.Toast(toastElement)
            toast.show()
        }
    }
}