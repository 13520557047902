import { createRouter, createWebHistory } from 'vue-router'
import AgbView from '../views/AgbView.vue'
import DatenschutzView from '../views/DatenschutzView.vue'
import HomeView from '../views/HomeView.vue'
import ImprintView from '../views/ImprintView.vue'
import ShopView from '../views/ShopView.vue'

import InitView from '../views/InitView.vue'
import NotFound from '../views/NotFound.vue'
import Loading from '../views/Loading.vue'

//Components
import Header from '../components/Header.vue'
import Menue from '../components/Menue.vue'
import initService from "@/assets/helpers/init"

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      default: HomeView,
      header: Header,
      menu: Menue
    },
    meta: {
      requiresInit: true
    }
  },
  {
    path: '/init',
    name: 'init',
    components: {
      default: InitView,
      header: Header
    },
    meta: {
      requiresInit: false
    }
  },
  {
    path: '/agb',
    name: 'agb',
    components: {
      default: AgbView,
      header: Header,
      menu: Menue
    },
    meta: {
      requiresInit: true
    }
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    components: {
      default: DatenschutzView,
      header: Header,
      menu: Menue
    },
    meta: {
      requiresInit: true
    }
  },
  {
    path: '/impressum',
    name: 'impressum',
    components: {
      default: ImprintView,
      header: Header,
      menu: Menue
    },
    meta: {
      requiresInit: true
    }
  },
  {
    path: '/shop',
    name: 'shop',
    components: {
      default: ShopView,
      header: Header,
      menu: Menue
    },
    meta: {
      requiresInit: true
    }
  },
  {
    path: '/loading',
    name: 'loading',
    component: Loading,
    meta: {
      requiresInit: false
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    components: {
      default: NotFound,
      header: Header,
      menu: Menue
    },
    meta: {
      requiresInit: true
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//router Guard
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresInit) && initService.isInitialised() == false) {
    next({name: 'init'})
  } else {
    next()
  }
})

export default router