/**
 * setzt die richtige Höhe für den Scroll Container es wird die Klasse des Containers erwartet und wenn nötig die Klassen der Container die abgezogen werden müssen
 * @param targetContainer
 * @param subtractContainer
 */
export default {
    setScrollerHeight(targetContainer = '', subtractContainer = []) {
        //kurz warten damit auch alles geladen wurde
        setTimeout(function () {
            let docHeight = document.body.clientHeight;
            if (targetContainer != '') {
                let containerHeight = docHeight;
                if (subtractContainer != []) {
                    let subtractHeight = 0;
                    for (let i in subtractContainer) {
                        let tmpContainer = document.querySelector(subtractContainer[i]);
                        if (tmpContainer != null) {
                            subtractHeight += tmpContainer.clientHeight;
                        }
                    }
                    if (Number(subtractHeight) > 0) {
                        containerHeight = Number(containerHeight) - Number(subtractHeight);
                    }
                    let domContainer = document.querySelector(targetContainer);
                    if (domContainer != null) {
                        domContainer.style.height = containerHeight + 'px';
                    }
                }
            }
        }, 100)
    }
}