<template>
  <div class="init">
    <div class="row mx-3">
      <div class="col-12 scroller">
        <h1 v-if="this.show_init">Erste Schritte</h1>
        <p v-if="this.show_init">Um die Nutzung der App so unkompliziert wie möglich zu gestalten, bitten wir Sie um ein paar kurze Angaben.</p>
        <h1 v-if="!this.show_init">Einstellungen</h1>
        <div class="init-step-amount">
          <p><strong>Für eine schnelle Preisermittlung sollten Sie eine für Sie typische Bestellmenge angeben</strong></p>
          <form class="init-form-amount">
            <label for="init_amount">Im Schnitt bestelle ich:</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" id="init_amount" name="init_amount" v-model="this.init_amount" @keyup="setValues($event,'init_amount')" placeholder="3000">
              <span class="input-group-text bg-color-main text-white">Liter</span>
            </div>
          </form>
          <div class="row fixed-bottom init-next-container mx-3">
            <div class="col-12 text-center d-grid">
              <button class="btn bg-color-main text-white" type="button" @click="loadStep('position')">Weiter</button>
            </div>
          </div>
          <div class="row fixed-bottom init-dots-container mx-3">
            <div class="col-12 text-center">
              <i class="fa-solid fa-circle"></i><i class="fa-light fa-circle mx-2" @click="loadStep('position')"></i>
            </div>
          </div>
        </div>
        <div class="init-step-position d-none">
          <p><strong>Um aktuelle Preise zu ermitteln, benötigen wir den zu beliefernden Standort</strong></p>
          <form class="init-form-position">
            <div class="input-group mb-3">
              <span class="input-group-text bg-color-main text-white">PLZ</span>
              <input type="text" class="form-control" id="init_zip" name="init_zip" v-model="this.init_zip" @keyup="setValues($event,'init_zip')">
            </div>
          </form>
          <div class="row fixed-bottom init-next-container mx-3">
            <div class="col-12 text-center d-grid">
              <button class="btn bg-color-main text-white" type="button" @click="initComplete()">Fertig!</button>
            </div>
          </div>
          <div class="row fixed-bottom init-dots-container mx-3">
            <div class="col-12 text-center">
              <i class="fa-light fa-circle" @click="loadStep('amount')"></i><i class="fa-solid fa-circle mx-2"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from "@/assets/helpers/storage"
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      init_amount: '',
      init_zip: '',
      init_steps: [
        'amount',
        'position'
      ],
      show_init: true
    }
  },
  methods: {
    setTemplateValues : async function() {
      let appData = Storage.readAppData();
      if(typeof appData['init_amount'] != 'undefined'){
        this.init_amount = appData['init_amount']
      }
      if(typeof appData['init_zip'] != 'undefined'){
        this.init_zip = appData['init_zip']
      }
      if(typeof appData['init_complete'] != 'undefined'){
        this.show_init = false
      }
    },
    setValues: async function(event, valueKey) {
      let appData = [];
      appData[valueKey] = event.target.value;
      Storage.writeAppData(appData);
    },
    loadStep: async function(step) {
      let toStep = this.init_steps[0];
      if(step!=''){
        toStep = step;
      }
      for(let s in this.init_steps){
        if(this.init_steps[s]!=toStep){
          document.getElementsByClassName('init-step-'+this.init_steps[s])[0].classList.add("d-none")
        }
      }
      document.getElementsByClassName('init-step-'+toStep)[0].classList.remove("d-none");
    },
    initComplete: async function() {
      let appData = [];
      appData['init_complete'] = true;
      Storage.writeAppData(appData);

      await this.$router.push('/')
    }
  },
  async beforeMount() {
    /* loading of tanks form api before component is mounted */
    await this.setTemplateValues();
  }
})
</script>