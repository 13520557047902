<template>
  <ion-menu side="end" menu-id="main-menu" content-id="main-content" type="overlay">
    <ion-content>
      <ion-list>
        <ion-item><router-link to="/" @click="closeMenu()" class="nav-link color-main">Home</router-link></ion-item>
        <ion-item><router-link to="/shop" @click="closeMenu()" class="nav-link color-main">Preisrechner</router-link></ion-item>
        <ion-item><router-link to="/init" @click="closeMenu()" class="nav-link color-main">Einstellungen</router-link></ion-item>
        <ion-item><router-link to="/impressum" @click="closeMenu()" class="nav-link color-main">Impressum</router-link></ion-item>
        <ion-item><router-link to="/agb" @click="closeMenu()" class="nav-link color-main">AGB</router-link></ion-item>
        <ion-item><router-link to="/datenschutz" @click="closeMenu()" class="nav-link color-main">Datenschutz</router-link></ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>

  <nav class="menu-fixed-bottom d-flex flex-row bg-white text-center">
    <div class="flex-fill"><router-link to="/" class="nav-link color-main"><i class="fas fa-home"></i></router-link></div>
    <div class="flex-fill"><a href="tel:+4965852749818" class="nav-link color-main"><i class="fas fa-phone"></i></a></div>
    <div class="flex-fill">
      <ion-menu-button class="color-main"></ion-menu-button>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonContent,
  IonList,
  IonItem,
  menuController
} from '@ionic/vue'

export default defineComponent ({
  components: {
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonContent,
    IonList,
    IonItem,
    menuController
  },
  methods: {
    closeMenu() {
      menuController.close('main-menu');
    }
  }
})
</script>