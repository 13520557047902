export default {
    //##########################################
    //App Daten aus Cookie lesen
    readAppData() {
        let appDataArr = new Array();
        if (typeof (Storage) !== "undefined") {//localStorage wird von Browser unterstützt
            var key;
            for (key in window.localStorage) {
                appDataArr[key] = window.localStorage[key];
            }
        } else {
            //Cookie auswerten
            let i, x, y, ARRcookies = document.cookie.split(";");
            for (i = 0; i < ARRcookies.length; i++) {
                x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
                y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
                x = x.replace(/^\s+|\s+$/g, "");
                appDataArr[x] = unescape(y);
            }
        }

        return appDataArr;
    },
    //App Daten aus Cookie lesen ENDE
    //##########################################

    //##########################################
    //App Daten in Cookie schreiben
    setCookie(c_name, value, exdays) {
        let exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        let c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value;
    },
    //App Daten in Cookie schreiben ENDE
    //##########################################

    //##########################################
    //App Daten in Cookie schreiben
    writeAppData(appData) {
        let appDataArr = this.readAppData();
        let key, key2;
        for (key in appData) {
            appDataArr[key] = appData[key];
        }

        if (typeof (Storage) !== undefined) {//localStorage wird von Browser unterstützt
            for (key2 in appDataArr) {
                window.localStorage.setItem(key2, appDataArr[key2]);
            }
        } else {
            for (key2 in appDataArr) {
                this.setCookie(key2, appDataArr[key2], 365);
            }
        }
        return true;
    },
    //App Daten in Cookie schreiben ENDE
    //##########################################

    //##########################################
    //App Daten löschen
    clearSingleAppData(key) {
        if (typeof (Storage) !== undefined) {
            window.localStorage.removeItem(key);
        }
    },
    //App Daten löschen ENDE
    //##########################################

    //##########################################
    //App Daten löschen
    clearAppData() {
        if (typeof (Storage) !== undefined) {
            window.localStorage.clear();
        }
    }
    //App Daten löschen ENDE
    //##########################################
}