<template>
  <div class="home">
    <div class="row">
      <div class="col-12 scroller">
        <ion-content class="home-bg" :scroll-y="false">
          <ion-refresher slot="fixed" @ionRefresh="reloadContent($event)">
            <ion-refresher-content></ion-refresher-content>
          </ion-refresher>

          <div class="row">
            <div class="col-12 text-white text-center mt-5">
              <p>Heizöl aktuell:</p>
            </div>
            <div class="col-12 preis text-white text-center" v-html="content"></div>
            <div class="col-12 text-white text-center mt-3">
              <p v-if="this.amount">bei Abnahme von {{this.amount}} Liter</p>
              <p v-if="this.zip">für PLZ {{this.zip}}.</p>
            </div>
            <div class="col-12 d-grid">
              <router-link to="/shop" class="btn bg-color-main text-white mx-3">Heizöl bestellen <i class="fas fa-chevron-right text-end"></i></router-link>
            </div>
            <div class="col-12 chart" v-html="chart">

            </div>
          </div>
        </ion-content>
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent } from 'vue';
import connector from "@/assets/helpers/connector"
import toast from "@/assets/helpers/toast"
import scroller from "@/assets/helpers/scroller"
import Storage from "@/assets/helpers/storage"
import {
  IonContent,
  IonRefresher,
  IonRefresherContent
} from '@ionic/vue'

export default defineComponent({
  components: {
    IonContent,
    IonRefresher,
    IonRefresherContent
  },
  data() {
    return {
      isLoaded: false,
      content: '',
      chart: '',
      amount: 0,
      zip: ''
    };
  },
  methods: {
    loadContent: async function () {
      let appData = Storage.readAppData();
      if (this.isLoaded) {
        return;
      }
      this.isLoaded = true;
      if(typeof appData['init_amount'] != 'undefined'){
        this.amount = appData['init_amount']
      }
      if(typeof appData['init_zip'] != 'undefined'){
        this.zip = appData['init_zip']
      }

      let mainData = new FormData();
      mainData.append('amount', appData['init_amount']);
      mainData.append('zip_code', appData['init_zip']);
      let response = await connector.apiCall('price', mainData);
      let noPrice = true;
      if(response != '') {
        if (response.error=='') {
          noPrice = false;
          this.content = response.price+' €/'+response.basic_amount+response.unit;
        }
      }

      if(noPrice == true){
        this.content = 'kein Preis gefunden';
      }

      /** API Call */
      this.chart = 'kein Chart';//await connector.apiCall('chart');
    },
    /**
     *
     * @param event
     * @returns {Promise<AxiosResponse<any>>}
     */
    async reloadContent(event = null) {
      this.content = '';
      this.isLoaded = false;
      try {
        await this.loadContent();
        if (event !== null) {
          setTimeout(() => {
            event.target.complete();
          }, 100);
        }
      } catch (error) {
        await toast.showToast('Die Seite konnte nicht geladen werden.', 'danger');
      }
    }
  },
  async beforeMount() {
    await this.loadContent();
  },
  mounted() {
    scroller.setScrollerHeight('.scroller', ['header','.menu-fixed-bottom']);
  }
})
</script>