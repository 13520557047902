import axios from 'axios'
const userKey = '671c4c7f81cdf766396d'; //userKey vom Contentserver24
const APIDomain = 'https://secure.contentserver24.de/api/apps/351/energieshop.php';//pfad zur API Datei

export default {
    apiCall (part, data) {
        let postParams = new FormData();
        postParams.append('userKey', userKey);
        postParams.append('apiPart', part);

        if(data!=undefined) {
            for (let pair of data.entries()) {
                postParams.append(pair[0], pair[1]);
            }
        }
        return axios
            .post(APIDomain,
                postParams
            )
            .then(response => response.data);
    }
}