<template>
  <div class="imprint">
    <div class="row mx-3 scroller">
      <div class="col-12"><h1>Impressum</h1></div>
      <div class="col-12 col-md-6">
        <p><strong>Das Ölmännchen GmbH</strong><br>
          Frankenhöhe 10<br>
          54310 Ralingen
        </p>
      </div>
      <div class="col-12 col-md-6">
        <p><strong>Das Ölmännchen <small>dö</small> GmbH</strong><br>
          Kotten 5<br>
          51491 Overath</p>
      </div>
      <div class="col-12">
        <p><strong>Niederlassung</strong><br>
          Europaallee 38<br>
          50226 Frechen</p>
      </div>
      <div class="col-12">
        <p>info@oelmaennchen.de<br>
        www.oelmaennchen.de</p>
      </div>
      <div class="col-12">
        <p>Registereintrag: HRB 44484<br>
        Amtsgericht Wittlich<br>
        Umsatzsteuer-ID:<br>
        Ust-IdNr:  DE 310780623<br>
        St.-Nr.:        42/657/00892</p>
        <p>Verantwortlich:
        Geschäftsführer:  W. Szablowski</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import scroller from "@/assets/helpers/scroller"

export default defineComponent({
  mounted() {
    scroller.setScrollerHeight('.scroller', ['header','.menu-fixed-bottom']);
  }
})
</script>